import React from "react";
import Layout from "../../../other/layout";
import Entrepreneurs from "../../../components/AC-StaticPages/VotreEntreprise/Subpages/Entrepreneurs";
import SEO from "../../../other/seo";

export default function entrepreneurs() {
  return (
    <Layout>
      <SEO title="Entrepreneurs" />

      <Entrepreneurs />
    </Layout>
  );
}
